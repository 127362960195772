<template>
  <div
    v-if="(promotionHistories.data && promotionHistories.data.data.length > 0) || loggedUser.roles.includes('super-admin')"
    class="col-md-6 col-xl-4 col__administrative-data"
  >
    <div class="card card--profile-contact-information">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="clearfix">
              <h3 class="float-start">
                <i
                  data-feather="server"
                  class="w-icon-24"
                /> Promotion History
              </h3>
            </div>
            <hr>
          </div>
        </div>

        <div class="row card--profile-contact-information">
          <div class="col-sm-3">
            <p class="mb-0">Next promotion <strong>{{ user.next_promotion_year }}</strong></p> <!-- el año se define por una fórmula, pedirla a isabel -->
          </div>
          <div
            v-if="loggedUser.roles.includes('super-admin')"
            class="col-sm-3"
          >
            <div>
              <p class="mb-0">Evaluation begins <strong>{{ user.next_promotion_year - user.total_years_prom }}</strong></p>
            </div>
          </div>
          <div
            v-if="loggedUser.roles.includes('super-admin')"
            class="col-sm-3"
          >
            <div>
              <p class="mb-0">Evaluations end <strong>{{ user.next_promotion_year - 1 }}</strong></p> <!-- valor automático -->
            </div>
          </div>
          <div
            v-if="loggedUser.roles.includes('super-admin')"
            class="col-sm-3"
          >
            <div>
              <p class="mb-0">Years until next promotion <strong>{{ user.total_years_prom }}</strong></p>
            </div>
          </div>
        </div>
        <hr>
        <div
          v-if="loading"
          class="loading position-relative"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
        <div
          v-else
          class="table-responsive-sm overflow-scroll"
        >
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Year</th>
                <th>Result</th>
                <th>Evaluation</th>
                <th>Document</th>
                <th
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="text-end"
                >
                  Actions
                </th>
                <th
                  v-else
                  class="text-end"
                >
                  View promotion
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="promotionHistory in promotionHistories.data.data"
                :key="promotionHistory.id"
              >
                <td><strong>{{ promotionHistory.promotion ? promotionHistory.promotion.year : '-' }}</strong></td>
                <td>
                  <span
                    v-if="promotionHistory.result"
                    class="badge bg-light-success"
                  >Positive</span>
                  <span
                    v-else
                    class="badge bg-light-danger"
                  >Negative</span>
                </td>
                <td v-html="promotionHistory.evaluation_table" />
                <td>
                  <a
                    v-if="promotionHistory.salary_history && promotionHistory.salary_history.files_.length > 0"
                    :href="promotionHistory.salary_history.files_[0].url"
                    target="_blank"
                  >
                    <i data-feather="file" />
                  </a>
                </td>
                <td class="text-end">
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-dark"
                  ><i data-feather="eye" /></a></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: true,
      paginator: {
        perPage: 10,
        currentPage: 1,
      },
      showingAll: false,
      minValues: 3,
      maxValues: 10,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      user: 'users/user',
      promotionHistories: 'users/promotionHistories',
    }),
  },
  async mounted() {
    await this.$store.dispatch('users/fetchUserPromotionHistory', { id: this.user.id })
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loading = false
  },
}
</script>
