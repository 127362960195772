<template>
  <div
    :class="`col-${colSize}`"
  >
    <div class="card card--profile-contact-information">
      <div
        v-b-toggle="`collapseBasicData`"
        class="card-header"
      >
        <h3 class="cart-title">
          <a data-action="collapse"><i
            data-feather="user"
            class="w-icon-24"
          /> Basic Data</a>
        </h3>
        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li v-if="!loading && loggedUser.roles.includes('super-admin')">
              <a
                @click="editBasicData($event)"
              ><i data-feather="edit-3" /></a>
            </li>
            <li>
              <a
                data-action="collapse"
              ><i data-feather="chevron-down" /></a>
            </li>
          </ul>
        </div>
      </div>
      <b-collapse
        :id="`collapseBasicData`"
        visible
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="row"
          >
            <div class="col">
              <div class="row">
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-6">
                      <p>
                        Begin date
                        <date-picker
                          v-if="editStatus"
                          v-model="editData.researcher_start_date"
                          format="D MMM Y"
                          value-type="format"
                          class="d-block w-50"
                        />
                        <strong v-else>{{ basicData.researcher_start_date ? basicData.researcher_start_date : '---' }}</strong>
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <p>
                        End date
                        <date-picker
                          v-if="editStatus"
                          v-model="editData.end_date"
                          format="D MMM Y"
                          value-type="format"
                          class="d-block w-50"
                        />
                        <strong v-else>{{ basicData.end_date ? basicData.end_date : '---' }}</strong>
                      </p>
                    </div>
                  </div>

                  <p>
                    Position <v-select
                      v-if="editStatus"
                      v-model="editData.position"
                      label="title"
                      :options="backgrounds"
                      :get-option-key="option => option.id"
                    /> <strong v-else>{{ basicData.position ? basicData.position.title : '---' }}</strong>
                  </p>
                  <p>
                    Host Institution <v-select
                      v-if="editStatus"
                      v-model="editData.institution"
                      label="name"
                      :options="institutions"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'institutions/filter')"
                    /> <strong v-else>{{ basicData.institution && basicData.institution.name ? basicData.institution.name : '---' }} </strong>
                  </p>
                  <p v-if="editData.institution_2 || editStatus">
                    Host Institution 2<v-select
                      v-if="editStatus"
                      v-model="editData.institution_2"
                      label="name"
                      :options="institutions"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'institutions/filter')"
                    /> <strong v-if="editData.institution_2 && !editStatus">{{ basicData.institution_2 && basicData.institution_2.name ? basicData.institution_2.name : '---' }} </strong>
                  </p>
                  <p>
                    Department <v-select
                      v-if="editStatus"
                      v-model="editData.department"
                      :disabled="!editData.institution"
                      label="name"
                      :options="editData.institution ? editData.institution.departments : []"
                      :get-option-key="option => option.id"
                    /> <strong v-else>{{ basicData.department && basicData.department.name ? basicData.department.name : '---' }}</strong>
                  </p>
                  <p v-if="editData.department_2 || editStatus">
                    Department 2<v-select
                      v-if="editStatus"
                      v-model="editData.department_2"
                      :disabled="!editData.institution_2"
                      label="name"
                      :options="editData.institution_2 ? editData.institution_2.departments : []"
                      :get-option-key="option => option.id"
                    /> <strong v-if="editData.department_2 && !editStatus">{{ basicData.department_2 && basicData.department_2.name ? basicData.department_2.name : '---' }}</strong>
                  </p>
                  <p>Next promotion
                    <strong v-if="!editStatus">{{ basicData.next_promotion_year ? basicData.next_promotion_year : '-' }}</strong>
                    <input
                      v-if="editStatus"
                      v-model="editData.next_promotion_year"
                      type="number"
                      class="form-control"
                      name="name"
                      @change="editData.end_year_promo = ($event.target.value - 1)"
                    >
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <p>Start year promotion
                        <strong v-if="!editStatus">{{ basicData.start_year_promo ? basicData.start_year_promo : '-' }}</strong>
                        <input
                          v-if="editStatus"
                          v-model="editData.start_year_promo"
                          type="number"
                          class="form-control"
                          name="name"
                        >
                      </p>
                    </div>
                    <div class="col-6">
                      <p>End year promotion
                        <strong v-if="!editStatus">{{ basicData.end_year_promo ? basicData.end_year_promo : '-' }}</strong>
                        <input
                          v-if="editStatus"
                          v-model="editData.end_year_promo"
                          type="number"
                          class="form-control"
                          name="name"
                          @change="editData.next_promotion_year = (parseInt($event.target.value) + 1)"
                        >
                      </p>
                    </div>
                  </div>
                  <p v-if="loggedUser.roles.includes('super-admin')">
                    Status <strong
                      v-if="!editStatus"
                      v-html="basicData.status"
                    />
                  </p><div
                    v-if="editStatus && loggedUser.roles.includes('super-admin')"
                    class="my-auto"
                  >
                    <div class="form-check form-check-success form-switch d-flex align-items-center">
                      <input
                        id="publishContent"
                        v-model="editData.active"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="ms-1 form-check-label"
                        for="publishContent"
                      >Active</label>
                    </div>
                  </div><!-- esta info es automática -->
                </div>
                <div class="col-sm-6">
                  <p>
                    Birthday (Age)
                    <date-picker
                      v-if="editStatus"
                      v-model="editData.birthday"
                      format="D MMM Y"
                      value-type="format"
                      class="d-block w-50"
                      :class="editData.birthday != basicData.birthday ? 'border border-warning' : ''"
                    />
                    <strong v-else>{{ basicData.birthday ? basicData.birthday : '---' }} ({{ basicData.age ? basicData.age : '---' }})</strong>
                  </p>
                  <p>
                    Type of leave
                    <v-select
                      v-if="editStatus"
                      v-model="editData.type_of_leave"
                      label="name"
                      :options="typeOfLeaves"
                      :get-option-key="option => option.id"
                    /> <strong v-else>{{ basicData.type_of_leave ? basicData.type_of_leave.name : '---' }}</strong>
                  </p>
                  <p>
                    Citizenship
                    <v-select
                      v-if="editStatus"
                      v-model="editData.citizenship"
                      label="name"
                      :options="citizenships"
                      :get-option-key="option => option.id"
                    /> <strong v-else>{{ basicData.citizenship ? basicData.citizenship.name : '---' }}</strong>
                  </p>
                  <p>
                    Coming from
                    <v-select
                      v-if="editStatus"
                      v-model="editData.country"
                      label="name"
                      :options="citizenships"
                      :get-option-key="option => option.id"
                      :reduce="option => option.id"
                    /><strong v-else>{{ basicData.country ? basicData.country.name : '---' }}</strong></p>
                  <p>
                    NIF/NIE
                    <input
                      v-if="editStatus"
                      v-model="editData.identify_document"
                      type="text"
                      class="form-control"
                      name="name"
                    >
                    <strong v-else>{{ basicData.identify_document ? basicData.identify_document : '---' }}</strong></p>
                  <p>
                    Social Security Number
                    <input
                      v-if="editStatus"
                      v-model="editData.social_security_number"
                      type="text"
                      class="form-control"
                      name="name"
                    > <strong v-else>{{ basicData.social_security_number ? basicData.social_security_number : '---' }}</strong></p>
                  <p>Call<strong>{{ basicData.enter_call ? basicData.enter_call.title : '-' }}</strong></p>
                  <p>Workplace <strong>{{ basicData.first_workplace ? basicData.first_workplace : '---' }}</strong></p>
                  <p>Hazards <strong>{{ basicData.first_hazard ? basicData.first_hazard : '---' }}</strong></p>
                </div>
                <div
                  v-if="editStatus"
                  class="mt-1"
                >
                  <button
                    v-if="!sending"
                    id="type-success"
                    class="btn btn-success"
                    @click="save"
                  >
                    <i data-feather="save" />
                    <span
                      class="ms-25 align-middle"
                    >Save and Exit</span>
                  </button>
                  <button
                    v-else
                    class="btn btn-outline-success"
                    type="button"
                    disabled=""
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span
                      class="ms-25 align-middle"
                    >Saving...</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
    colSize: { type: Number, required: false, default: 12 },
  },
  data() {
    return {
      loading: true,
      editStatus: false,
      editData: {
        researcher_start_date: null,
        birthday: null,
        identify_document: null,
        position: null,
        institution: null,
        institution_2: null,
        department: null,
        department_2: null,
        active: null,
        citizenship: null,
        country: null,
        social_security_number: null,
      },
      sending: false,
      errors: '',
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      basicData: 'users/basicData',
      institutions: 'institutions/institutions',
      backgrounds: 'backgrounds/backgrounds',
      citizenships: 'citizenships/items',
      typeOfLeaves: 'typeOfLeave/items',
    }),
  },
  watch: {
    basicData() {
      this.editData = { ...this.basicData }
    },
  },
  async mounted() {
    await this.$store.dispatch('users/fetchUserBasicData', this.user.id)
    await this.$store.dispatch('backgrounds/filter', { researcher: { id: this.user.id } })
    await this.$store.dispatch('citizenships/fetch')
    await this.$store.dispatch('typeOfLeave/fetch')
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loading = false
  },
  methods: {
    editBasicData(event) {
      event.stopPropagation()
      this.editStatus = !this.editStatus
      if (this.editStatus) {
        this.editData = { ...this.basicData }
      }
    },
    async save() {
      this.sending = true
      try {
        await this.$store.dispatch('users/updateBasicData', {
          data: this.editData,
          userId: this.user.id,
        })
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '') {
        Vue.swal(
          'Basic data has been saved correctly!',
          '',
          'success',
        )
        await this.$store.dispatch('users/fetchUserBasicData', this.user.id)
      } else {
        console.log(this.errors)
        Vue.swal('Error on save data!', '', 'error')
      }
      this.sending = false
      this.editStatus = false
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
