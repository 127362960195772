<template>
  <div
    :class="`col-${colSize}`"
  >
    <div class="card card--table">
      <div
        v-b-toggle="`collapseContact`"
        class="card-header"
      >
        <h3 class="cart-title">
          <a data-action="collapse"><i
            data-feather="layers"
            class="w-icon-24"
          /> ICREA's contract</a>
        </h3>
        <div
          v-if="loggedUser.roles.includes('super-admin')"
          class="heading-elements"
        >
          <ul class="list-inline mb-0">
            <li>
              <span
                class="dis-ib"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add contract"
              >
                <a
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasAddContract"
                  aria-controls="offcanvasAddContract"
                  class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                  @click="openUploadContract($event)"
                ><i data-feather="plus" /></a>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <b-collapse
        :id="`collapseContact`"
        visible
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="table-responsive-sm overflow-scroll"
          >
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Document</th>
                  <th>Description</th>
                  <th>File</th>
                  <th
                    v-if="loggedUser.roles.includes('super-admin')"
                    class="text-end"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(contract, index) in icreaContracts.data.data"
                  :key="index"
                  class="h-57"
                >
                  <td>{{ contract.name }}</td>
                  <td><small>{{ contract.description }}</small></td>
                  <td>
                    <span
                      v-if="contract.files.length > 0"
                      class="dis-ib"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View file"
                    ><a
                      :href="contract.files[0].url"
                      target="_blank"
                      class="btn btn-icon rounded-circle btn-flat-dark"
                    ><i data-feather="file" /></a></span>
                  </td>
                  <td
                    v-if="loggedUser.roles.includes('super-admin')"
                    class="text-end"
                  >
                    <span
                      class="dis-ib"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit"
                    ><a
                      href="#"
                      class="btn btn-icon rounded-circle btn-flat-dark"
                      @click="openUploadContract($event, contract)"
                    ><i data-feather="edit-3" /></a></span>
                    <span
                      class="dis-ib"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete"
                    ><a
                      href="#"
                      class="btn btn-icon rounded-circle btn-flat-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#modalDeleteWithInput"
                      @click="showDeleteModal(contract.id)"
                    ><i data-feather="trash-2" /></a></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="showingAll"
            class="card-body"
          >
            <div class="d-flex align-items-center justify-content-between">
              <b-pagination
                v-model="paginator.currentPage"
                :total-rows="icreaContracts.total"
                :per-page="paginator.perPage"
                aria-controls="table"
                @change="changePage"
              />
            </div>
          </div>
          <div
            v-if="icreaContracts.total > minValues"
            class="d-flex align-items-center justify-content-between"
          >
            <a
              class="btn btn-sm btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Display all blocks"
              @click="showAllData"
            >
              {{ showingAll ? 'Show less' : 'Show all' }} <i data-feather="eye" /></a>
          </div>
        </div>
      </b-collapse>
    </div>
    <ItemInfoSheet :table-id="contractInfoSheet">
      <template #info-sheet-title>
        Add Contract
      </template>
      <template
        #info-sheet
      >
        <div class="offcanvas-body">
          <div
            v-if="loggedUser.roles.includes('super-admin')"
            class="mb-1"
          >
            <label
              for="nameseniorcall"
              class="form-label"
            >Researcher</label>
            <v-select
              v-model="selectedResearcher"
              label="name"
              :options="users"
              :get-option-key="option => option.id"
              @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
            />
            <hr>
          </div>
          <div class="mb-1 form-group required">
            <label
              for=""
              class="form-label"
            >Document name</label>
            <input
              v-model="newContract.name"
              required
              type="text"
              class="form-control"
            >
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Description</label>
            <textarea
              id=""
              v-model="newContract.description"
              name=""
              cols="30"
              rows="3"
              class="form-control"
            />
          </div>
          <div class="mb-1 form-group required">
            <label
              for=""
              class="form-label"
            >Begin date</label>
            <date-picker
              v-model="newContract.begin_date"
              format="D MMM Y"
              value-type="format"
              class="w-100"
            />
          </div>
          <hr>
          <div class="mb-1 col-12 form-group required">
            <label
              :for="`formFile-contract`"
              class="form-label"
            >Upload file</label>
            <input
              :id="`formFile-contract`"
              :ref="`file-contract`"
              class="form-control"
              type="file"
            >
          </div>
          <!-- <hr>
          <div class="mb-1 form-group required">
            <label
              for=""
              class="form-label"
            >Interlocutor</label>
            <v-select
              v-model="newContract.interlocutor"
              label="name"
              :options="evaluatorsTotal"
              :get-option-key="option => option.id"
              :reduce="e => e.name"
              @search="onSearch({ name: $event }, 'users/fetchEvaluatorsNoConvo')"
            />
          </div> -->
        </div>
        <div class="offcanvas-footer mt-auto">
          <button
            type="button"
            class="btn btn-success mb-1 d-grid w-100"
            @click="addContract"
          >
            Upload contract
          </button>
        </div>
      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse, BPagination } from 'bootstrap-vue'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import 'vue2-datepicker/index.css'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
    ItemInfoSheet,
    DatePicker,
    BPagination,
    vSelect,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
    colSize: { type: Number, required: false, default: 12 },
  },
  data() {
    return {
      loading: true,
      newContract: {
        files: [],
      },
      selectedInfoSheet: '',
      paginator: {
        perPage: 10,
        currentPage: 1,
      },
      showingAll: false,
      minValues: 3,
      maxValues: 10,
      contractInfoSheet: 0,
      selectedResearcher: null,
    }
  },
  computed: {
    ...mapGetters({
      icreaContracts: 'users/icreaContracts',
      loggedUser: 'auth/admin',
      evaluatorsTotal: 'users/evaluators',
      users: 'users/fakeUsers',
    }),
  },
  async mounted() {
    await this.$store.dispatch('users/fetchUserIcreaContracts', { id: this.user.id })
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loading = false
  },
  methods: {
    openUploadContract(event, item = null) {
      event.stopPropagation()
      if (!item) {
        this.newContract = {
          files: [],
        }
      } else {
        // Editas el objeto en tiempo real, no me gusta
        this.newContract = item
      }
      this.$store.dispatch('modals/toggleTableClicked', this.contractInfoSheet)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async addContract() {
      const file = this.$refs['file-contract'].files[0]
      if (file || this.newContract.files.length > 0) {
        if (file) {
          this.newContract.files.push({
            name: this.newContract.name,
            original_name: file.name,
            description: this.newContract.description,
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            file,
          })
        }
        const icreaContract = {
          contract: this.newContract,
          user_id: this.selectedResearcher ? this.selectedResearcher.id : this.user.id,
        }
        try {
          if (!this.newContract.id) {
            await this.$store.dispatch('users/uploadContract', icreaContract)
          } else {
            await this.$store.dispatch('users/updateContract', icreaContract)
          }
          Vue.swal('Contract uploaded successfully!', '', 'success').then(async () => {
            this.loading = true
            await this.$store.dispatch('users/fetchUserIcreaContracts', { id: this.user.id })
            this.loading = false
            setTimeout(() => {
              // eslint-disable-next-line no-undef
              feather.replace({
                width: 14,
                height: 14,
              })
            }, 500)
          })
        } catch (e) {
          this.$toastr.error('', 'Error uploading a contract')
        }

        this.newContract = {
          files: [],
        }
        this.$store.dispatch('modals/toggleInfoItemSheet', false)
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      } else {
        Vue.swal('Please, upload a file first!', '', 'warning')
      }
    },
    async showDeleteModal(id) {
      Vue.swal({
        title: 'Are you sure?',
        text: 'This action cannot be reversed',
        icon: 'warning',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/icrea-contracts/${id}`)
          Vue.swal('Deleted successfully!', '', 'success').then(() => {
            this.$store.dispatch('users/fetchUserIcreaContracts', { id: this.user.id })
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          })
        }
      })
    },
    async changePage(page) {
      this.loading = true
      await this.$store.dispatch('users/fetchUserIcreaContracts', { id: this.user.id, page, perPage: this.maxValues })
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async showAllData() {
      this.showingAll = !this.showingAll
      this.loading = true
      if (this.showingAll) {
        this.paginator.currentPage = 1
        await this.$store.dispatch('users/fetchUserIcreaContracts', { id: this.user.id, page: this.paginator.currentPage, perPage: this.maxValues })
      } else {
        await this.$store.dispatch('users/fetchUserIcreaContracts', { id: this.user.id, perPage: this.minValues })
      }
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
